import React from "react"
import { withMainLayout } from 'components/layouts'
import { SystemPageMessage } from 'components/common'

const Page404 = () => {
  return (
    <div className='systemPage'>
      <div className='container'>
        <SystemPageMessage 
          errorNumber={404}
          errorTitle={'Страница не найдена'}
          errorText={'К сожалению, такой страницы не существует.'}
          errorButton={true}
        />
      </div>
    </div>
  )
}

export default withMainLayout(Page404)
